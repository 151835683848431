
import Portfolio from "./port";
function App() {
  return (
    <div className="App">
  <Portfolio/>
    </div>
  );
}

export default App;
